@import 'src/Assets/variables';
.live-class-page {
  .body-content {
    min-height: 250px;
  }

  // @media only screen and (min-width: $break-point) {
  //   overflow-y: auto;
  &-back {
    margin: 0px 0px 15px 25px;
  }

  &-header {
    padding-left: 5%;
    border-bottom: 4px solid rgba(240, 239, 239, 0.8);
    padding-bottom: 30px;

    &-heading {
      display: block;
      font-family: 'Poppins-Bold';
      color: #4f4f4f;
      font-size: 24px;
    }

    &-details {
      margin-top: 20px;

      &-email {
        font-family: 'Poppins-Regular';
        color: $heading;
        font-weight: bold;
        margin-right: 30px;
      }

      &-phone {
        color: $heading;
        display: block;
      }
    }

    &-calendar {
      float: left;
      display: inline;
      font-size: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 10px;
        height: 15px;
      }
    }
  }

  &-live-chat-class {
    margin-top: 20px;
    height: 500px;
    background-color: #fff;
    overflow: scroll;
    position: relative;

    .header-live-chat {
      background: #fb9d3c;
      position: absolute;
      width: 100%;
      top: 0;
      height: auto;
      padding: 15px;
      border-radius: 10px 10px 0px 0px;
      color: #fff;
      font-weight: bold;

      .user-joined {
        position: absolute;
        right: 15px;
        top: 15px;

        img {
          margin-right: 10px;
        }
      }
    }

    .content-live-chat {
      height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-top: 50px;
      padding-bottom: 75px;
    }

    .chat {
      font-size: 10px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 10px 25px;

      .message {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: left;
        flex: 1 1 0%;

        b {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 12px;
          margin-bottom: 5px;

          span {
            background-color: $monthly;
            color: #fff;
            font-size: 8px;
            margin-left: 5px;
            padding: 2.5px 5px;
            border-radius: 5px;
          }
        }

        img.attachment {
          width: 70%;
        }
      }

      img {
        width: 50px;
        display: flex;
        margin-right: 10px;
      }
    }

    .footer-live-chat {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: #332e2c;
      color: #fff;

      input {
        flex-grow: 1;
        background-color: #332e2c;
        border: 0;
        color: #fff;
      }

      input:focus {
        border: 0;
        outline: none !important;
      }

      img {
        flex-grow: 0;
        width: 5%;
        margin: 2.5%;
        cursor: pointer;
      }
    }
  }

  &-content {
    padding: 10px 0%;

    .info {
      width: 100%;
      background-color: #ccf;
      text-align: center;
      padding: 50px;
    }
  }

  &-btn {
    width: 90%;
    margin-left: 5%;

    &.add {
      margin-top: 20px;
    }

    &.history {
      margin-top: 20px;
    }
  }

  .ongoing {
    padding: 5px 10px;
    color: #fff;
    background: #86da4e;
    position: absolute;
    font-size: 12px;
    border-radius: 5px;
    right: 20px;
  }

  .no-chat {
    text-align: center;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  .control {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .custom-button {
      width: 48%;

      button {
        width: 100%;
      }
    }
  }
}

.content-video {
  text-align: center;
  padding: 50px 5px;
  background-color: #eee;
  border-radius: 15px;

  &.wrong {
    background-color: #fcc !important;
  }
}

.pop-quiz {
  width: 100%;
  background-color: #ccf;
  text-align: center;
  padding: 50px;
}